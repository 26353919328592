﻿//
// Home
//

//50th modal
.modal{
	visibility: hidden;
	display: none;
	background-color: transparent;
	transition: all .25s ease-out;
	text-align: center;
	.akt-50th{
		margin: 0px auto;
		display: flex;
		align-items: center;
		width: auto;
		height: 100vh;
		text-align: center;
		max-width: 705px;
		img{
			margin: 0px auto;
			width:auto;
			height:auto;
			max-width: 100%;
			max-height: 100%;
		}
	}
	&.is-active{
		background-color: rgba(#FFFFFF, 0.95);
		visibility: visible;
		display: block;
	}
}

.section{
}
.section-heading{
	@include contents-inner();
	position: relative;
	padding-bottom: 2em;
	@include media-breakpoint-down(sm) {
		position: static;
	}
	h2{
		@extend .heading-icon;
	}
	.btn-area{
		display: inline-block;
		position: absolute;
		top: 0px;
		right: 20px;
		@include media-breakpoint-down(sm) {
			top: auto;
			bottom: 60px;
			left: 0px;
			display: block;
			text-align: center;
		}
	}
}
.section--inner{
	@include contents-inner();
}
.section--mainvisual{
	@include contents-inner();
	padding-top: 45px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
	.mainvisual{
		width: calc(100% - 254px);
		padding-bottom: 50px;
		@include media-breakpoint-down(md) {
			width: calc(100% + 30px);
			margin-left: -15px;
			margin-right: -15px;
		}
		.main-slider{
			margin-bottom: 15px;
			.swiper-slide{
				position: relative;
				.mv-contents{
					position: absolute;
					left: 0px;
					right: 0px;
					bottom: 0px;
					background-color: rgba(#000000, 0.6);
					color: #fff;
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;
					align-items: center;
					@include media-breakpoint-down(sm) {
						position: relative;
					}
					@include media-breakpoint-down(xs) {
						flex-wrap: wrap;
						padding-bottom: 0.8em;
					}
					.text-area{
						padding: 0.8em 1em;
						width: calc(100% - 110px);
						@include media-breakpoint-down(xs) {
							width: 100%;
						}
					}
					.btn-area{
						width: 110px;
						@include media-breakpoint-down(xs) {
							width: 100%;
							text-align: center;
						}
						a{
							display: inline-block;
							background-color: #DA0D25;
							height: 34px;
							line-height: 34px;
							border-radius: 17px;
							font-size: rem(12);
							position: relative;
							padding: 0px 1em 0px 35px;
							color: #fff;
							text-decoration: none;
							transition: all .25s ease-out;
							&:before{
								content: "";
								position: absolute;
								top: 4px;
								left: 4px;
								bottom: 4px;
								width: 25px;
								background-color: #fff;
								border-radius: 50%;
							}
							&:after{
								position: absolute;
								top: 50%;
								margin-top: -5px;
								left: 13px;
								content: "";
								display: block;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 5px 0 5px 8px;
								border-color: transparent transparent transparent #DA0D25;
							}
							&:hover{
								box-shadow: 0px 0px 1px 1px rgba(#000, 0.1);
								transform: scale(0.9);
							}
						}
					}
				}
			}
		}
		.thumb-slider{
			text-align: center;
			.swiper-slide{
				width: 86px;
				cursor: pointer;
				&.swiper-slide-thumb-active{
					border: 3px solid $orange;
					box-sizing: border-box;
					width: 82px;
				}
			}
			.swiper-button-next,
			.swiper-button-prev {
				background-color: $orange;
				top: 0px;
				bottom: 0px;
				height: auto;
				margin-top: 0px;
				background-size: 50% auto;
			}
			.swiper-button-next{ right: 0px; }
			.swiper-button-prev{ left: 0px; }
		}
	}
	.tab{
		width:  234px;
		padding-bottom: 50px;
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
		.tab-content{
			height: 100%;
		}
	}
}
.section--banner{
	width: 100%;
	@include media-breakpoint-down(md) {
		width: calc(100% - 254px);
	}
	@include media-breakpoint-down(xs) {
		width: 100%;
	}
}
.section--tv-guide{
	padding: 50px 0px;
	@include media-breakpoint-down(sm) {
		position: relative;
		padding-bottom: 100px;
	}
}
.tv-guide-slider{
	.swiper-wrapper{
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		& > a{
			color: $gray-800;
			text-decoration: none;
			transition: all .1s ease-out;
			&:hover{
				box-shadow: 0px 0px 1px 1px rgba(#000, 0.1);
				transform: scale(1.02);
			}
		}
		.swiper-slide{
			width: 280px;
			background-color: #fff;
			padding: 15px 10px;
			position: relative;
			.icon-time{
				display: block;
				margin-bottom: 10px;
			}
			.tv-guide--name{
				text-align: center;
				font-weight: bold;
				font-size: rem(18);
				margin: 0px;
				padding: 10px 0px 0px 0px;
			}
			&.is-live{
				&:before{
					position: absolute;
					content: "";
					display: block;
					top: 0px;
					left: 0px;
					right: 0px;
					bottom: 0px;
					border: 4px solid $red;
				}
				.is-live--message{
					position: absolute;
					display: inline-block;
					top: 0px;
					right: 0px;
					background-color: $red;
					color: #fff;
					padding: 0.7em 0.5em 0.2em 0.5em;
					font-size: rem(15);
				}
			}
		}
	}
	.swiper-button-next,
	.swiper-button-prev{
		width: 44px;
		height: 44px;
		background-size: 40% auto;
		background-color: $orange;
	}
}
.section--media{
	background-color: $gray-100;
	overflow: hidden;
	padding: 80px 0px 70px 0px;
	.media--panel{
		@include contents-inner();
	}
}
.section--information{
	.section--inner{
		.row{
			.col-6{
				@include media-breakpoint-down(xs) {
					text-align: center;
				}
			}
		}
	}
}

.facebook-wrapper {
    max-width: 500px;
    margin: 0 auto;
}
.facebook-wrapper > .fb-page {
    width: 100%;
}
.facebook-wrapper > .fb-page > span,
.facebook-wrapper iframe {
    width: 100% !important;
}