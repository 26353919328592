// px to rem
$font-size-root: 16 !default;

@function rem($px) {
	@return ($px / $font-size-root) + rem;
}

// Foundation sites ver.
@function rem-calc($px) {
	@return rem($px);
}

// pcでのみホバー
@mixin hover-responsive(){
	transition: all .25s ease-out;
	&:hover, &:focus{
		@content;
	}
}

// contents inner
@mixin contents-inner(){
	max-width: 1040px;
	padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto;
	@include media-breakpoint-down(md) {
		padding-left: 15px;
		padding-right: 15px;
	}
}