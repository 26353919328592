//
// Header
//

.header {
	background-color: #fff;
	position: relative;
}
// Layout
.header {
	&-inner {
		@include contents-inner();
		padding-top: 12px;
		padding-bottom: 12px;
		position: relative;
		@include media-breakpoint-up(sm) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			nav {
				flex: 1 1 auto;
			}
		}
		@include media-breakpoint-down(sm) {
			padding-right: 100px;
		}
		@include media-breakpoint-down(xs) {
			padding-right: 20px;
		}
		.header-logo{
			width: 197px;
			max-width: 197px;
			min-width: 150px;
			@include media-breakpoint-down(sm) {
				width: 40%;
			}
			a{
				width: 100%;
				display: block;
				img{
					&:first-child{
						width: 59.39%;
					}
					&:last-child{
						width: 38.07%;
					}
				}
			}
		}
		.header-site-navigation{
			@include media-breakpoint-down(sm) {
				display: none;
				visibility: hidden;
			}
		}
		.header-banner-area{
			width: calc(100% - 197px - 310px);
			@include media-breakpoint-down(sm) {
				width: calc(100% - 197px);
				padding-left: 10px;
			}
			@include media-breakpoint-down(xs) {
				width: 100%;
				padding: 10px 0px 0px 0px;
			}
		}
	}
	.header-site-navigation{
		width: 310px;
		padding: 0px 10px;
		.header-site-navigation--list{
			padding: 0px;
			margin: 0px;
			text-align: center;
			li{
				display: inline-block;
				font-size: rem(13);
				&:before{
					top: 0.3em;
					left: 5px;
				}
				a{
					color: $gray-800;
				}
				&:first-child{
					margin-right: 10px;
				}
			}
		}
		.header-site-navigation--search{
			position: relative;
			.input-text{
				width: 100%;
				@extend .simple-design;
				margin-bottom: 4px;
				padding: 5px 10px;
				background-color: #fff;
			}
			.btn-search{
				@extend .reset-style;
				padding: 0px;
				background-color: transparent;
				border: none;
				position: absolute;
				right: 4px;
				top: 4px;
				width: 35px;
			}
		}
	}
}

// Logo
.header-logo {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 100%;
	display: flex;
	align-items: center;
}

// Button
.header-button-container {
	position: absolute;
	top: 20px;
	right: 20px;
	@include media-breakpoint-down(sm) {
		top: 10px;
	}
	button {
		@extend .reset-style;
		width: 56px;
		height: 56px;
		border: none;
		color: #fff;
		border-radius: 5px;
		background-color: $orange;
		cursor: pointer;
		font-size: rem(11);
		padding: 3px 0px 0px 0px;
		img{
			width: 37px;
		}
		p{
			padding: 3px 0px 0px 0px;
			margin: 0px;
		}
	}
}

// Nav
.header-menu{
	background-color: rgba($orange, 0.05);
	@include media-breakpoint-down(sm) {
		padding: 20px;
	}
	.header-site-navigation{
		display: none;
		visibility: hidden;
		@include media-breakpoint-down(sm) {
			display: block;
			visibility: visible;
			margin: 20px auto 0px auto;
		}
	}
}
.header-nav {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	height: 100%;
	max-width: 800px;
	display: flex;
	align-items: stretch;
	justify-content: space-around;
	flex-wrap: wrap;
	> li {
		a{
			display: block;
			font-weight: bold;
			color: $gray-800;
			padding: 15px;
			text-decoration: none;
			position: relative;
			&:before{
				content: "";
				display: block;
				width: 100%;
				height: 48px;
				margin-bottom: 10px;
				background-position: center center;
				background-repeat: no-repeat;
				transition: all .25s ease-out;
				@include media-breakpoint-down(sm) {

				}
			}
			&:after{
				content: "";
				display: block;
				width: 0em;
				height: 4px;
				border-radius: 2px;
				background-color: rgba($orange, 0.3);
				position: absolute;
				bottom: 10px;
				left: 50%;
				margin-left: 0em;
				transition: all .25s ease-out;
			}
			@include hover-responsive() {
				&:before{
					transform: rotateY(180deg);
				}
				&:after{
					width: 2.4em;
					margin-left: -1.2em;
				}
			}
		}
		&.item--01 a:before{//番組一覧
			background-size: auto 41px;
			background-image: url("#{$imgDir}/common/icon-header-nav-01.svg");
		}
		&.item--02 a:before{//ニュース・天気
			background-size: auto 37px;
			background-image: url("#{$imgDir}/common/icon-header-nav-02.svg");
		}
		&.item--03 a:before{//番組表
			background-size: auto 47px;
			background-image: url("#{$imgDir}/common/icon-header-nav-03.svg");
		}
		&.item--04 a:before{//イベント
			background-size: auto 46px;
			background-image: url("#{$imgDir}/common/icon-header-nav-04.svg");
		}
		&.item--05 a:before{//アナウンサー
			background-size: auto 48px;
			background-image: url("#{$imgDir}/common/icon-header-nav-05.svg");
		}
		&.item--06 a:before{//リンク
			background-size: auto 41px;
			background-image: url("#{$imgDir}/common/icon-header-nav-06.svg");
		}
	}
}