.wrap-sponsor{
    background-color: #fff9a2;
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    @include media-breakpoint-down(lg) {
        padding-right: 0px;
    }
    @include media-breakpoint-down(sm) {
        padding-left: 0px;
    }
    &.is-fixed{
        position: fixed;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 100;
    }
    .btn-sponsor{
        position: absolute;
        background-color: #FFF100;
        border: none;
        top: 0px;
        left: 0px;
        bottom: 0px;
        text-align: center;
        width: 60px;
        padding: 0px 0px 46px 0px;
        span{
            display: block;
            height: 100%;
            min-height: 5em;
            line-height: 1em;
            margin: 0px auto;
            writing-mode: tb-rl;
            color: $orange;
            font-weight: bold;
        }
        &:before, &:after{
            position: absolute;
            right: 0px;
            width: 100%;
            display: block;
            text-align: center;
            background-color: $orange;
            color: #fff;
            height: 20px;
            line-height: 20px;
        }
        &:before{
            content: "非表示";
            bottom: 26px;
            font-size: rem(14);
        }
        &:after{
            content: "";
            bottom: 3px;
            background-image: url("#{$imgDir}/common/icon-double-arrow.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 75%;
        }
        &.is-hide{
            top: -55px;
            left: 0px;
            bottom: auto;
            width: auto;
            display: inline-block;
            padding: 0px 80px 0px 10px;
            span{
                writing-mode: lr-tb;
                line-height: 55px;
                min-height: auto;
                min-height: initial;
            }
            &:before, &:after{
                width: 55px;
                right: 10px;
            }
            &:before{
                content: "表示";
                top: 5px;
                bottom: auto;
            }
            &:after{
                bottom: 5px;
            }
        }
        @include media-breakpoint-down(sm) {
            top: -55px;
            left: 0px;
            bottom: auto;
            width: auto;
            display: inline-block;
            padding: 0px 80px 0px 10px;
            span{
                writing-mode: lr-tb;
                line-height: 55px;
                min-height: auto;
                min-height: initial;
            }
            &:before, &:after{
                width: 55px;
                right: 10px;
            }
            &:before{
                top: 5px;
                bottom: auto;
            }
            &:after{
                bottom: 5px;
                transform: rotate(180deg);
            }
            &.is-hide{
                &:after{
                    transform: rotate(0deg);
                }
            }
        }
    }
    .area-sponsor{
        @include contents-inner();
        padding-top: 20px;
        max-height: 500px;
        overflow: hidden;
        transition: all .25s ease-out;
        &.is-hide{
            max-height: 0px;
            padding-top: 0px;
        }
    }
}
.footer{
    background-color: $gray-700;
    position: relative;
    margin-top: 80px;
    .footer-link-top{
        position: absolute;
        right: 10px;
        top: -55px;
        a{
            display: inline-block;
            font-size: rem(16);
            background-color: $orange;
            line-height: 44px;
            border-radius: 22px;
            text-decoration: none;
            color: #fff;
            padding: 0px 50px 0px 1em;
            position: relative;
            &:before{
                position: absolute;
                content: "";
                display: block;
                bottom: 5px;
                right: -5px;
                width: 49px;
                height: 62px;
                background-image: url("#{$imgDir}/common/icon-pagetop.png");
                background-repeat: no-repeat;
                background-size: 100% auto;
            }
        }
    }
    .footer-logo{
        position: absolute;
        right: 0px;
        bottom: 10px;
    }
    &--inner{
        @include contents-inner();
        padding-top: 10px;
        padding-right: 230px;
        @include media-breakpoint-down(md) {
            padding-bottom: 55px;
        }
        a{
            color: #fff;
            font-size: rem(13);
        }
        .list-icon{
            margin: 0px;
            padding: 0px;
            li{
                display: inline-block;
                margin: 0px 25px 5px 0px;
            }
        }
        .copyright{
            color: #fff;
            font-size: rem(12);
            margin: 0px;
            padding-bottom: 10px;
        }
    }
}