﻿//
// Page
//
ul{
    &.list-icon{
        li{
            padding-left: 18px;
            position: relative;
            list-style: none;
            &:before{
                position: absolute;
                top: 0.5em;
                left: 3px;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 5px 8px;
                border-color: transparent transparent transparent $orange;
            }
        }
        &.icon-white{
            li{
                &:before{
                    border-color: transparent transparent transparent $white;
                }
            }
        }
    }
}
.list-archive{
    display: block;
    margin: 0px;
    padding: 0px;
    &--item{
        display: block;
        background-color: #F0F0F0;
        &:nth-child(odd){
            background-color: #fff;
        }
        a{
            display: block;
        }
        .archive-title{
            margin: 0px;
            padding: 0.5em 0.5em 0px 0.5em;
            color: #0072BB;
            font-size: rem(13);
        }
        time{
            display: block;
            padding: 0px 0.5em 0.5em 0.5em;
            color: $gray-800;
            font-size: rem(12);
        }
    }
}

.box-scroll{
    width: 100%;
	overflow: hidden;
	position: relative;
    .scrollable{
        height: 450px;
		margin-right: -17px; /* オーバーレイスクロールバーを消す */
		padding-right: 17px; /* オーバーレイスクロールバーを消す */
		overflow: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		.adjustment{
			margin-right: 15px; /* スクロールバーの幅 */
		}
		&.is-scrollvar{
			padding-right: 0px;
		}
	}
	.scrollbar{
		position: absolute;
		top: 0;
		right: 2px;
		bottom: 0;
		width: 2px; /* スクロールバーの幅 */
		background-color: #F2B6B6;
		// overflow: hidden;
		.scrollbar-thumb {
			min-height: 50px;
			background-color: #D65046;
			margin-left: -2px;
            width: 6px;
            border-radius: 2px;
		}
	}
}