﻿//
// Common
/*
-> Parts
-> Heading
-> Btn
-> Form
-> Decorations

*/
// Parts
.thumbnail{
	display: block;
	background-size: cover;
	background-repeat: no-repeat;
	&:after{
		content: "";
		display: block;
		padding-top: 56%;
	}
}
// Heading
.heading-icon{
	margin-left: 55px;
	position: relative;
	border-bottom: 3px solid $orange;
	font-size: rem(30);
	font-weight: bold;
	padding: 0.2em 0px;
	@include media-breakpoint-down(xs) {
		margin-left: 40px;
		font-size: rem(20);
	}
	&:before{
		position: absolute;
		top: 5px;
		left: -55px;
		content: "";
		display: block;
		width: 48px;
		height: 42px;
		background-image: url("#{$imgDir}/common/icon-heading.png");
		background-size: 100% auto;
		background-repeat: no-repeat;
		@include media-breakpoint-down(xs) {
			left: -40px;
			width: 36px;
			height: 32px;
		}
	}
}

// Btn
.btn-center{
	text-align: center;
}
.btn-right{
	text-align: right;
}

a.btn,
.btn{
	display: inline-block;
	background-color: $orange;
	color: #fff;
	transition: all .25s ease-out;
	&:hover{
		opacity: 0.7;
	}
	&.size-small{
		border-radius: 14px;
		padding: 0.2em 1em;
		font-size: rem(14);
	}
	&.size-middle{
		min-width: 200px;
		border-radius: 22px;
		font-size: rem(18);
	}
	&.type-arrow{
		position: relative;
		padding-left: 1.5em;
		&:before{
			position: absolute;
			top: 50%;
			margin-top: -5px;
			left: 8px;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 8px;
			border-color: transparent transparent transparent #fff;
		}
	}
}

// Form
form{
	input{
		&.reset-style{
			// margin: 0;
			// padding: 0;
			border: none;
			border-radius: 0;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		&.simple-design{
			@extend .reset-style;
			height: 42px;
			border-radius: 21px;
			border: 1px solid #ABABAB;
		}
	}
}
button{
	&.reset-style{
        cursor: pointer;
        outline: none;
        appearance: none;
	}
}

// Decorations
.icon-time{
	position: relative;
	font-size: rem(17);
	font-weight: bold;
	padding-left: 23px;
	&:before{
		content: "";
		display: block;
		width: 18px;
		height: 18px;
		position: absolute;
		top: 50%;
		margin-top: -9px;
		left: 0px;
		background-image: url("#{$imgDir}/home/icon-time.svg");
		background-size: 100% auto;
		background-repeat: no-repeat;
	}
}

//panel
.panel--wrap{
	.panel--content{
		height: 100%;
		display: block;
		background-color: #fff;
		color: $gray-800;
		text-decoration: none;
		transition: all .1s ease-out;
		.panel--info{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
			time{
				padding: 0.3em 0.5em 0.3em 13px;
				font-size: rem(14);
			}
			p{
				margin: 0px;
				padding: 10px 13px;
				font-size: rem(14);
			}
		}
		&:hover{
			box-shadow: 0px 0px 1px 1px rgba(#000, 0.1);
			transform: scale(1.04);
		}
	}
}

//label
.label{
	display: inline-block;
	padding: 0.1em 0.5em;
	background-color: $orange;
	color: #fff;
	font-size: rem(13);
	&--news{
		background-color: #CF5858;
	}
	&--information{
		background-color: $orange;
	}
	&--feature{
		background-color: $green;
	}
	&--program{
		background-color: $blue;
	}
}

//tab
.tab{
	&.tab-style01{
		.nav-tabs{
			align-items: stretch;
			justify-content: space-between;
			border: none;
			.nav-item{
				width: 49%;
				background-color: #E5E5E5;
				border: none;
				text-align: center;
				.nav-link{
					border: none;
					height: 100%;
					box-shadow:0px -8px 20px -10px rgba(#000, 0.3) inset;
					overflow: hidden;
					color: $gray-800;
					font-weight: bold;
					&.active{
						box-shadow: none;
					}
					//秋田の天気
					&.menu-02{
						&:after{
							display: block;
							content: "";
							width: 10px;
							margin: 0px auto;
							height: 10px;
							border-right: 1px solid rgba(#707070, 0.5);
							border-bottom: 1px solid rgba(#707070, 0.5);
							transform: rotate(45deg);
						}
					}
				}
			}
		}
		.tab-content{
			.tab-pane{
				padding: 7px;
				background-color: #fff;
			}
		}
	}
	&.tab-style02{
		.nav-tabs{
			align-items: stretch;
			justify-content: space-between;
			border: none;
			margin-bottom: 35px;
			.nav-item{
				width: 20%;
				background-color: #fff;
				.nav-link{
					border: none;
					height: 100%;
					overflow: hidden;
					text-align: center;
					border-radius: 0px;
					padding: 0.8em 5px;
					border: none;
					border-bottom-style: solid;
					border-bottom-width: 3px;
					transition: all .25s ease-out;
					vertical-align: middle;

					display: flex;
					align-items: center;
					justify-content: center;
					br{
						display: none;
						@include media-breakpoint-down(xs) {
							display: block;
						}
					}
					&.active{
						border: none;
						box-shadow: none;
						background-color: #6A6A6A;
						color: #fff!important;
						position: relative;
						overflow: visible;
						&:after{
							transition: all .25s ease-out;
							position: absolute;
							bottom: -15px;
							left: 50%;
							margin-left: -10px;
							content: "";
							display: block;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 15px 10px 0 10px;
							border-color: #6A6A6A transparent transparent transparent;
						}
					}
				}
				&:first-child{
					.nav-link{
						border-bottom-color: #6A6A6A;
						color: #6A6A6A;
						&.active{
							background-color: #6A6A6A;
						}
						&:after{ border-color: #6A6A6A transparent transparent transparent; }
						&:hover{
							background-color: #6A6A6A;
							color: #fff;
						}
					}
				}
				&:first-child+*{
					.nav-link{
						border-bottom-color: #CF5858;
						color: #CF5858;
						&.active{
							background-color: #CF5858;
						}
						&:after{ border-color: #CF5858 transparent transparent transparent; }
						&:hover{
							background-color: #CF5858;
							color: #fff;
						}
					}
				}
				&:first-child+*+*{
					.nav-link{
						border-bottom-color: #EB5E01;
						color: #EB5E01;
						&.active{
							background-color: #EB5E01;
						}
						&:after{ border-color: #EB5E01 transparent transparent transparent; }
						&:hover{
							background-color: #EB5E01;
							color: #fff;
						}
					}
				}
				&:first-child+*+*+*{
					.nav-link{
						border-bottom-color: #529730;
						color: #529730;
						&.active{
							background-color: #529730;
						}
						&:after{ border-color: #529730 transparent transparent transparent; }
						&:hover{
							background-color: #529730;
							color: #fff;
						}
					}
				}
				&:first-child+*+*+*+*{
					.nav-link{
						border-bottom-color: #5B84AE;
						color: #5B84AE;
						&.active{
							background-color: #5B84AE;
						}
						&:after{ border-color: #5B84AE transparent transparent transparent; }
						&:hover{
							background-color: #5B84AE;
							color: #fff;
						}
					}
				}
			}
		}
	}
}